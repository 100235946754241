<template>
  <div class="flex-1 flex w-full">
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="confirmChangeParcelle"
    >
      <slot slot="message_body">{{ message_confirmation }} Oui / Non</slot>
      <slot slot="button_valide">Oui</slot>
      <slot slot="button_cancel">Non</slot>
    </ValidateModal>
    <div class="flex-1 flex w-full relative flex-col map-parcelle">
      <div
        class="absolute w-full h-full bg-black z-20 opacity-50"
        :class="{
          hidden: !is_loading && !error_parcelle_not_found,
        }"
      ></div>
      <StylesMap class="z-10" :map="map" :moduleStore="moduleStore" />
      <div
        class="absolute bottom-0 left-0 sm:w-full z-10 flex px-6 justify-center py-4 flex-col w-86"
        style="background-color: #ffffffbd"
      >
        <Elevations ref="elevations" :informations="informations"></Elevations>
      </div>
      <loader
        v-if="is_loading"
        class="absolute z-20 center-element"
        :size_bg_promy="0"
        :color="'#ffffff'"
      ></loader>
      <div
        class="absolute z-20 center-element bg-white p-4 rounded-lg"
        v-if="error_parcelle_not_found"
      >
        {{ error_parcelle_not_found }}
      </div>
      <div class="w-full h-full lg:h-60-screen" id="mapbox"></div>
    </div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/mapbox'
import parcelle from '@/mixins/cadastre/parcelle'
import batiment from '@/mixins/cadastre/batiment'
import informations_map from '@/mixins/dossierPromoteurs/informations/map'
import helpers from '@/mixins/helpers'
import calculeHelpers from '@/mixins/cadastre/calculeHelpers'
import sourcesAndLayers from '@/mixins/cadastre/sourcesAndLayers'
import Elevations from './Elevations'
import ChangeParcelle from './ChangeParcelle'
import AggregateParcelle from './AggregateParcelle'
import { mapGetters } from 'vuex'
import urbanisme from '@/mixins/dossierPromoteurs/urbanisme'

export default {
  components: {
    Elevations,
    ChangeParcelle,
    AggregateParcelle,
  },
  mixins: [
    helpers,
    calculeHelpers,
    Mapbox,
    parcelle,
    batiment,
    sourcesAndLayers,
    informations_map,
    urbanisme,
  ],
  data() {
    return {
      moduleStore: 'parcelle',
      parcelles_selected: [],
      altimetries: [],
      id_parcelles_selected: [],
      preserved_parcelles_voisines: [],
      message_confirmation: 'Voulez-vous sauvegarder votre sélection  ?',
      showModal: false,
      id_parcelle_selected: null,
    }
  },
  computed: {
    ...mapGetters({
      infos_cadastre: 'parcelle/infos_cadastre',
      markers_altimetrie: 'parcelle/markers_altimetrie',
      is_corner_changed: 'parcelle/is_corner_changed',
      infos_parcelles: 'parcelle/infos_parcelles',
    }),
  },
  props: {
    is_loading: {
      type: Boolean,
      required: true,
    },
    informations: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.initMap()
  },

  methods: {
    isEmptyObject(object) {
      return _.isEmpty(object)
    },
    getInfosParcelles(data) {
      this.$emit('getInfosParcelles', data)
    },

    initMap() {
      let coords = this.informations.center_parcelle_principale.length
        ? this.informations.center_parcelle_principale
        : [4.85, 45.75]
      this.$store.commit('defaultmap/SET_MAP', this.createMap(coords))
      this.map.on('load', () => {
        this.map.resize()
        this.setBaseLayers()

        this.loadDataToMap()
      })
      this.map.on('style.load', this.preserveLayers)
    },

    loadDataToMap() {
      if (!_.isEmpty(this.infos_parcelles)) {
        let vm = this
        this.removeMarkersAndAdditionalLayers(this.markers_altimetrie)
        if (
          this.informations.current_parcelle &&
          !Object.keys(this.informations.current_parcelle).length
        ) {
          this.informations.current_parcelle =
            this.infos_parcelles.cadastre_principal.parcelle
          this.informations.current_parcelle.properties.adresse =
            this.informations.adresse
          this.informations.parcelles_voisines = this
            .preserved_parcelles_voisines.length
            ? this.preserved_parcelles_voisines
            : []
        }
        if (this.informations.altimetries.length) {
          this.$refs.elevations.addCornerCrossMarkersAltimetrie()
        } else {
          vm.$refs.elevations.addCornersWithElevation()
        }
        if (this.informations.taille_murs.length) {
          this.addLengthWellToMap(this.informations.taille_murs, 'parcelle')
        }

        this.$store.commit(
          'parcelle/PARCELLES_AUTOUR',
          this.getParcellesAutour(this.features_autour),
        )
        this.fitBoundsGeojsonInMap(
          this.$turf.featureCollection([
            this.informations.current_parcelle,
            ...this.informations.parcelles_voisines,
          ]),
        )
        if (!this.informations.surface_terrain) {
          this.setInfosParcelles([
            this.informations.current_parcelle,
            ...this.informations.parcelles_voisines,
          ])
        }
        this.addLayerGeoParcelles()
        this.map.on('click', this.selectOrUnselectParcelleVoisines)
        this.id_parcelles_selected = [
          this.informations.current_parcelle,
          ...this.informations.parcelles_voisines,
        ].map((parcelle) => parcelle.properties.id)
        this.setPaintParcellesSelected()
        this.parcelles_selected = [
          this.informations.current_parcelle,
          ...this.informations.parcelles_voisines,
        ]
      }
    },
    getParcellePrincipalThroughCenterOfAllParcelles(parcelles) {
      if (!parcelles.length) return null
      let center = this.$turf.pointOnFeature(
        this.$turf.featureCollection(parcelles),
      )
      return parcelles.find((parcelle) =>
        this.$turf.booleanPointInPolygon(center, parcelle),
      )
    },
    selectOrUnselectParcelleVoisines(e) {
      if (!this.is_corner_changed) {
        let features = this.map.queryRenderedFeatures(e.point, {
          layers: [this.source_and_layers_geo_parcelles[1]],
        })
        if (features.length) {
          this.id_parcelle_selected = features[0].properties.id
          if (
            !_.includes(this.id_parcelles_selected, this.id_parcelle_selected)
          ) {
            this.checkConfirmationForSelectParcelle([
              ...this.parcelles_selected,
              features[0],
            ])
          } else
            this.checkConfirmationForSelectParcelle(
              this.parcelles_selected.filter(
                (parcelle) =>
                  parcelle.properties.id !== this.id_parcelle_selected,
              ),
            )
        }
      }
    },
    isParcellePrincipalChanged(parcelles_selected) {
      let new_current_parcelle =
        this.getParcellePrincipalThroughCenterOfAllParcelles(parcelles_selected)
      let is_parcelle_principal_changed =
        (this.informations.current_parcelle
          ? this.informations.current_parcelle.properties.id
          : -1) !==
        (new_current_parcelle ? new_current_parcelle.properties.id : null)
      return is_parcelle_principal_changed
    },
    checkConfirmationForSelectParcelle(parcelles_selected) {
      if (this.isParcellePrincipalChanged(parcelles_selected)) {
        this.message_confirmation =
          'Si vous changer la parcelle principale, vos données seront supprimées, vous devez les ressaisir.'
        this.showModal = true
      } else {
        this.confirmChangeParcelle()
      }
    },
    confirmChangeParcelle() {
      this.showModal = false
      if (!_.includes(this.id_parcelles_selected, this.id_parcelle_selected)) {
        this.$http
          .get('get-parcelles/' + this.id_parcelle_selected)
          .then((response) => {
            let parcelle = response.data.cadastre_principal[0].parcelle
            this.setAddressParcelle(parcelle).then(() => {
              this.parcelles_selected.push(parcelle)
              this.updateParcelles()
              this.$refs.elevations.addCornersWithElevation()
            })
          })
        this.id_parcelles_selected.push(this.id_parcelle_selected)
      } else {
        this.parcelles_selected = this.parcelles_selected.filter(
          (parcelle) => parcelle.properties.id !== this.id_parcelle_selected,
        )
        this.updateParcelles()
        _.pull(this.id_parcelles_selected, this.id_parcelle_selected)
      }

      this.$refs.elevations.addCornersWithElevation()
      this.setPaintParcellesSelected()
    },
    setAddressParcelle(parcelle, reset_dossier_promoteur = false) {
      if (parcelle) {
        let center = this.$turf.pointOnFeature(parcelle).geometry.coordinates
        return this.$http
          .get('admin/reverse-address', {
            params: {
              lon: center[0],
              lat: center[1],
            },
          })
          .then(
            (response) => {
              let features = response.data.url_gouv_reverse.features
              if (response.data.url_gouv_reverse.features.length) {
                parcelle.properties.adresse = features[0].properties.label
                if (reset_dossier_promoteur) {
                  features[0].geometry.coordinates = center
                  this.$emit('setAddress', features[0], true)
                }
              } else {
                parcelle.properties.adresse = this.informations.adresse
              }
            },
            (error) => {
              console.error(error)
            },
          )
      }
    },

    updateParcelles() {
      let is_parcelle_principal_changed = this.isParcellePrincipalChanged(
        this.parcelles_selected,
      )
      let new_current_parcelle =
        this.getParcellePrincipalThroughCenterOfAllParcelles(
          this.parcelles_selected,
        )

      this.informations.current_parcelle = new_current_parcelle
      this.informations.parcelles_voisines = this.parcelles_selected.filter(
        (item) =>
          item.properties.id !==
          (new_current_parcelle ? new_current_parcelle.properties.id : null),
      )
      this.setInfosParcelles(this.parcelles_selected)
      if (is_parcelle_principal_changed) {
        this.preserved_parcelles_voisines = [
          ...this.informations.parcelles_voisines,
        ]
        this.setAddressParcelle(this.informations.current_parcelle, true)
      } else {
        this.updateZonesUrbaine(this.informations)
      }
    },
    setPaintParcellesSelected() {
      if (!this.id_parcelles_selected.length) {
        this.map.setPaintProperty(
          this.source_and_layers_geo_parcelles[1],
          'fill-color',
          '#8F6DB0',
        )
      } else {
        this.map.setPaintProperty(
          this.source_and_layers_geo_parcelles[1],
          'fill-color',
          [
            'match',
            ['get', 'id'],
            this.id_parcelles_selected,
            '#00b7ab',
            '#8F6DB0',
          ],
        )
      }
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove()
      this.$store.commit('defaultmap/SET_MAP', null)
    }
  },
}
</script>
<style lang="scss">
@import '../../../assets/styles/Mapbox/control-zoom.scss';
.map-parcelle {
  .mapboxgl-ctrl-group {
    border-radius: 10px;
    margin-top: 24px !important;
    margin-left: 24px !important;
  }
  .hidden-loader {
    display: none !important;
  }
  .translate-center {
    transform: translate(-50%, -50%);
  }
  .center-element {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
</style>
<style lang="scss">
.icon-circle {
  @apply flex text-black justify-center text-base items-center font-bold  font-main w-5 h-5 border-solid  border-white border-2;
  border-radius: 50%;
}
.marker {
  background-color: #ffffff78 !important;
  color: black !important;
  font-weight: bold;
  font-size: 12px !important;
  border-radius: 15px;
  padding: 1px 4px 1px 4px;
}
.map-parcelle {
  .mapboxgl-popup-content {
    border-radius: 13px !important;
  }
}
</style>
